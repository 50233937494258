@import './../../../../scss/theme-bootstrap';

// Sign-in Form. This applies to both the gnav dropdown and the standalone
// perlgem page.
.signin-block {
  padding: 1em 0;
  width: 100%;
  text-align: #{$ldirection};
  .elc-user-state-logged-in & {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 1em 0 0;
    }
  }
  &__forms {
    .elc-user-state-logged-in & {
      display: none;
    }
    .signin-block__form {
      &--register {
        .signin-block__main {
          input[name='FIRST_NAME'],
          input[name='LAST_NAME'] {
            display: none;
          }
        }
        .signin-block__footer {
          background-color: $color-lighter-gray;
          margin: 1em 2em 2em;
          padding: 0;
          .signin-block {
            &__checkboxes {
              .signin-block__checkbox {
                &--terms-loyalty {
                  display: none;
                }
                &--terms,
                &--email-list {
                  .label {
                    vertical-align: text-top;
                  }
                }
              }
            }
            &__submit {
              width: auto;
            }
          }
        }
      }
      &--signin {
        padding-bottom: 2em;
        .signin-block {
          &__main {
            .signin-block {
              &__submit {
                display: block;
                margin-top: 20px;
                width: auto;
              }
              &__forgot-password {
                float: #{$ldirection};
                margin-top: 20px;
                padding-top: 5px 0 0;
              }
            }
          }
        }
      }
      &--register,
      &--signin {
        .signin-block__main {
          .signin-block {
            &__title {
              color: $color-black;
              font-size: 50px;
              font-family: $font--heading;
              line-height: 60px;
            }
            &__toggle {
              padding: 0.7em 0 1.5em;
              &__cta {
                &.link {
                  border-bottom: none;
                  text-decoration: none;
                  &:hover {
                    border-bottom: none;
                    border-color: transparent;
                    text-decoration: none;
                  }
                }
              }
            }
            &__field {
              &--email,
              &--password {
                color: $color-black;
              }
            }
          }
        }
      }
    }
  }
  &--signin {
    .signin-block__form--register {
      display: none;
    }
    .signin-block__form--signin {
      display: block;
    }
  }
  &__form--signin {
    display: none;
  }
  &__main,
  &__footer {
    padding: 0 2em;
  }
  &__title {
    text-align: center;
    font-size: 2em;
    margin: 0;
  }
  &__subtitle {
    font-size: 1.5em;
    line-height: 1;
    text-align: center;
  }
  &__field {
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    &--first-name,
    &--last-name {
      width: 49%;
    }
    &--first-name {
      float: #{$ldirection};
    }
    &--last-name {
      float: #{$rdirection};
    }
  }
  &__toggle-password {
    padding: 4px 0 9px;
    &__option {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      &--hide {
        display: none;
      }
      .signin-block--show-password & {
        &--show {
          display: none;
        }
        &--hide {
          display: inline-block;
        }
      }
    }
  }
  &__footer {
    background-color: $color-gray;
    padding: 1em;
  }
  &__forgot-password {
    color: $color-black;
    display: block;
    padding: 11px 0;
  }
  &__submit {
    width: 100%;
    margin-top: 9px;
  }
  &__toggle {
    text-align: center;
    padding: 1em;
    &__cta {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__authed {
    @include pie-clearfix;
    display: none;
    @include breakpoint($landscape-up) {
      min-width: 370px;
    }
    &__nav {
      @include breakpoint($landscape-up) {
        min-height: 400px;
        padding: 0 30px 30px;
        float: #{$ldirection};
      }
      &__title {
        padding-#{$ldirection}: 20px;
        margin: 0;
        // Wait for js to render the title text as it's raw, user-entered
        // mustache.
        display: none;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          padding: 0;
          line-height: 1;
          margin: 0 0 15px;
        }
        &--rendered {
          display: block;
        }
      }
      &__signout-link {
        font-size: 14px;
        border-top: solid 1px $color-black;
        border-bottom: solid 1px $color-black;
        display: block;
        padding-#{$ldirection}: 20px;
        @include breakpoint($landscape-up) {
          border: none;
          display: inline;
          padding: 0;
          line-height: 1;
        }
      }
    }
    &__content {
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 20px 0 30px);
        float: #{$rdirection};
        width: 300px;
        border-#{$ldirection}: solid 1px $color-light-gray;
        min-height: 400px;
      }
    }
    .elc-user-state-logged-in & {
      display: block;
    }
  }
}

// auth states visibility
.elc-user-state-anonymous,
.elc-user-state-logged-in {
  .hide_anon {
    display: none;
  }
}
